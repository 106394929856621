import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    BooleanInput,
    NumberField,
    ChipField,
    ArrayField,
    SingleFieldList,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin'

export const productFeatureEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="created" />
            <DateField source="updated" />
            <BooleanInput source="visible" />
            <TextInput source="name" />
            <TextInput source="unitId" />
            <TextInput source="unitCount" />
            <TextInput source="defaultAmount" />
            <TextInput source="description" />
            <ReferenceArrayInput
                source="featureIds"
                reference="product/product"
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
                source="featureCategoryIds"
                reference="product/feature_category"
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
)

export const productFeatureList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <NumberField source="id" />
                <DateField source="created" />
                <TextField source="name" />
                <NumberField source="unitCount" />
                <ReferenceField
                    source="unitId"
                    reference="common/unit_of_measure"
                >
                    <TextField source="abbreviation" />
                </ReferenceField>
                <ChipField source="featureType.name" />
                <ArrayField source="products">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="featureCategories">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    )
}
