import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    BooleanInput,
    Show,
    NumberField,
} from 'react-admin'

export const cartCartItemEdit = ({ ...props }) => (
    <Show {...props}>
        <SimpleForm>
            <TextField source="id" />
        </SimpleForm>
    </Show>
)

export const cartCartItemList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <DateField source="updated" />
                <ReferenceField source="productId" reference="product/product">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField source="cartId" reference="cart/cart">
                    <TextField source="id" />
                </ReferenceField>
                <NumberField source="quantity" />
                <NumberField source="amount" />
            </Datagrid>
        </List>
    )
}
