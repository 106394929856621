import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    BooleanInput,
    Show,
    NumberField,
} from 'react-admin'

export const paymentPaymentEdit = ({ ...props }) => (
    <Show {...props}>
        <SimpleForm>
            <TextField source="id" />
        </SimpleForm>
    </Show>
)

export const paymentPaymentList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <ReferenceField
                    source="transactionId"
                    reference="transaction/transaction"
                >
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField
                    source="transactionPaymentId"
                    reference="transaction/transaction_payment"
                >
                    <TextField source="id" />
                </ReferenceField>
                <NumberField source="amount" />
                <TextField source="processorId" />
            </Datagrid>
        </List>
    )
}
