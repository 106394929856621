import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
} from 'react-admin'

export const transactionTransactionEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="created" />
            <DateField source="updated" />
            <ReferenceField source="userId" reference="user/user">
                <TextField source="displayName" />
            </ReferenceField>
            <ReferenceField
                source="originId"
                reference="transaction/transaction_origin"
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                source="statusId"
                reference="transaction/transaction_status"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="statusId" />
            <TextField source="statusRetries" />
            <DateField source="placed" />
            <ReferenceField
                source="bagId"
                reference="transaction/transaction_bag"
            >
                <TextField source="id" />
            </ReferenceField>
            <BooleanField source="potentialFraud" />
            <TextField source="locale" />
            <BooleanField source="canceled" />
            <TextField source="stateId" />
            <TextField source="currency" />
            <TextField source="user/agent" />
            <TextField source="deviceType" />
        </SimpleForm>
    </Edit>
)

export const transactionTransactionList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <DateField source="updated" />
                <ReferenceField source="userId" reference="user/user">
                    <TextField source="displayName" />
                </ReferenceField>
                <ReferenceField
                    source="originId"
                    reference="transaction/transaction_origin"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    source="statusId"
                    reference="transaction/transaction_status"
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="statusRetries" />
                <DateField source="placed" />
                <BooleanField source="potentialFraud" />
                <TextField source="locale" />
                <BooleanField source="canceled" />
                <TextField source="currency" />
            </Datagrid>
        </List>
    )
}
