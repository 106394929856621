import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'

const apiUrl = process.env.REACT_APP_API_URL as string

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const { jwt } = JSON.parse(localStorage.getItem('auth') as string)
    options.headers.set('Authorization', `Bearer ${jwt}`)
    return fetchUtils.fetchJson(url, options)
}
const dataRestProvider = simpleRestProvider(apiUrl, httpClient)

const dataProvider = {
    ...dataRestProvider,
    update: (resource, params) => {
        if (resource !== 'posts' || !params.data.pictures) {
            // fallback to the default implementation
            return dataRestProvider.update(resource, params)
        }
        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */

        // Freshly dropped pictures are File objects and must be converted to base64 strings
        const newPictures = params.data.pictures.filter(
            p => p.rawFile instanceof File,
        )
        const formerPictures = params.data.pictures.filter(
            p => !(p.rawFile instanceof File),
        )

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                })),
            )
            .then(transformedNewPictures =>
                dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                }),
            )
    },
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject

        reader.readAsDataURL(file.rawFile)
    })

export default dataProvider
