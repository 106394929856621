import React from 'react'
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    DateField,
    BooleanInput,
    BooleanField,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin'

export const productFeatureCategoryCreate = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="visible" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
)

export const productFeatureCategoryEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="created" />
            <DateField source="updated" />
            <BooleanInput source="visible" />
            <TextInput source="name" />
            <ReferenceArrayInput
                source="featureIds"
                reference="product/feature"
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
                source="productCategoryIds"
                reference="product/product_category"
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
)

export const productFeatureCategoryList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <BooleanField source="visible" />
                <TextField source="name" />
                <ArrayField source="features">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="productCategories">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    )
}
