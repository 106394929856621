import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    BooleanInput,
    Show,
    NumberField,
} from 'react-admin'

export const paymentPaymentLogEdit = ({ ...props }) => (
    <Show {...props}>
        <SimpleForm>
            <TextField source="id" />
        </SimpleForm>
    </Show>
)

export const paymentPaymentLogList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="paymentId" reference="payment/payment">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField
                    source="actionId"
                    reference="payment/payment_action"
                >
                    <TextField source="id" />
                </ReferenceField>
                <BooleanField source="success" />
                <NumberField source="amount" />
                <DateField source="created" />
                <TextField source="attributes" />
                <NumberField source="creatorId" />
                <TextField source="creator_name" />
                <TextField source="processed_response" />
                <NumberField source="processorId" />
            </Datagrid>
        </List>
    )
}
