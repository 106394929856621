import React from 'react'
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
} from 'react-admin'

export const userLoginTypeEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
)

export const userLoginTypeList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
            </Datagrid>
        </List>
    )
}
