import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    BooleanInput,
} from 'react-admin'

export const commonCountryEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="iso3" />
            <TextField source="iso2" />
            <TextField source="phonecode" />
            <TextField source="capital" />
            <TextField source="currencyId" />
            <TextField source="tld" />
            <TextField source="native" />
            <TextField source="region" />
            <TextField source="subregion" />
            <TextField source="latitude" />
            <TextField source="longitude" />
            <TextField source="emoji" />
            <TextField source="emojiu" />
        </SimpleForm>
    </Edit>
)

export const commonCountryList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="iso3" />
                <TextField source="iso2" />
                <TextField source="phonecode" />
                <TextField source="capital" />
                <TextField source="currencyId" />
                <TextField source="tld" />
                <TextField source="native" />
                <TextField source="region" />
                <TextField source="subregion" />
                <TextField source="latitude" />
                <TextField source="longitude" />
                <TextField source="emoji" />
                <TextField source="emojiu" />
            </Datagrid>
        </List>
    )
}
