import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    NumberField,
    Datagrid,
    TextField,
    Show,
} from 'react-admin'

export const transactionTransactionItemShow = ({ ...props }) => (
    <Show {...props}>
        <SimpleForm>
            <TextField source="id" />
        </SimpleForm>
    </Show>
)

export const transactionTransactionItemList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <ReferenceField
                    source="transactionId"
                    reference="transaction/transaction"
                >
                    <TextField source="id" />
                </ReferenceField>
                {/* <ReferenceField source="productId" reference="product/product">
                    <TextField source="id" />
                </ReferenceField> */}
                <TextField source="sku" />
                <TextField source="description" />
                <NumberField source="amount" />
                <NumberField source="quantity" />
                <DateField source="displayName" />
                <NumberField source="taxAmount" />
                <NumberField source="discountAmount" />
            </Datagrid>
        </List>
    )
}
