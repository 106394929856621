import React from 'react'
import {
    List,
    Edit,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ReferenceInput,
    DateInput,
} from 'react-admin'

export const notificationNotificationEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <DateField source="created" />
            <DateField source="updated" />
            <ReferenceInput source="languageId" reference="common/language">
                <TextInput source="description" />
            </ReferenceInput>
            <ReferenceInput
                source="notificationTypeId"
                reference="notification/notification_type"
            >
                <TextInput source="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <DateInput source="title" />
            <TextInput source="body" />{' '}
        </SimpleForm>
    </Edit>
)

export const notificationNotificationList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <DateField source="updated" />
                <ReferenceField source="languageId" reference="common/language">
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField
                    source="notificationTypeId"
                    reference="notification/notification_type"
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name" />
            </Datagrid>
        </List>
    )
}
