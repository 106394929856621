import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    BooleanInput,
} from 'react-admin'

export const commonStateEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="iso" />
            <TextInput source="name" />
            <TextInput source="iso3" />
            <NumberInput source="phonecode" />
        </SimpleForm>
    </Edit>
)

export const commonStateList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField source="countryId" reference="common/country">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="countryCode" />
                <DateField source="fipsCode" />
                <TextField source="iso2" />
                <TextField source="latitude" />
                <TextField source="longitude" />
            </Datagrid>
        </List>
    )
}
