import React, { ReactElement, useState, useEffect } from 'react'
import {
    fetchUtils,
    Admin,
    Resource,
    ListGuesser,
    UpdateParams,
} from 'react-admin'

import polyglotI18nProvider from 'ra-i18n-polyglot'

import authProvider from './authProvider'
import themeReducer from './themeReducer'
import { Login, Layout } from './layout'
import englishMessages from './i18n/en'
import customRoutes from './routes'

import { userUserList, userUserCreate, userUserEdit } from './user/user'
import dataProvider from './dataProvider'
import { userUserRoleEdit, userUserRoleList } from './user/user_role'

import {
    transactionTransactionEdit,
    transactionTransactionList,
} from './transaction/transaction'
import {
    transactionTransactionBagShow,
    transactionTransactionBagList,
} from './transaction/transaction_bag'
import {
    transactionTransactionItemShow,
    transactionTransactionItemList,
} from './transaction/transaction_item'
import {
    transactionTransactionPaymentShow,
    transactionTransactionPaymentList,
} from './transaction/transaction_payment'
import {
    transactionTransactionOriginEdit,
    transactionTransactionOriginList,
} from './transaction/transaction_origin'

import { productProductEdit, productProductList } from './product/product'
import {
    productProductCategoryCreate,
    productProductCategoryEdit,
    productProductCategoryList,
} from './product/product_category'
import {
    productProductTypeEdit,
    productProductTypeList,
} from './product/product_type'
import { productFeatureEdit, productFeatureList } from './product/feature'
import {
    productFeatureTypeEdit,
    productFeatureTypeList,
} from './product/feature_type'

import { paymentPaymentEdit, paymentPaymentList } from './payment/payment'
import {
    paymentPaymentActionEdit,
    paymentPaymentActionList,
} from './payment/payment_action'
import {
    paymentPaymentGatewayEdit,
    paymentPaymentGatewayList,
} from './payment/payment_gateway'
import {
    paymentPaymentLogEdit,
    paymentPaymentLogList,
} from './payment/payment_log'
import {
    paymentCreditCardTypeEdit,
    paymentCreditCardTypeList,
} from './payment/credit_card_type'
import { cartCartEdit, cartCartList } from './cart/cart'

import { cartCartItemEdit, cartCartItemList } from './cart/cart_item'
import { providerProviderEdit, providerProviderList } from './provider/provider'
import { siteSiteEdit, siteSiteList } from './site/site'
import { commonCountryEdit, commonCountryList } from './common/country'
import { commonCityEdit, commonCityList } from './common/city'
import { commonStateEdit, commonStateList } from './common/state'
import { commonLanguageEdit, commonLanguageList } from './common/language'
import { userUserStateEdit, userUserStateList } from './user/user_state'
import { userLoginTypeEdit, userLoginTypeList } from './user/login_type'
import {
    notificationNotificationEdit,
    notificationNotificationList,
} from './notification/notification/index'
import {
    notificationNotificationTypeEdit,
    notificationNotificationTypeList,
} from './notification/notification_type'
import {
    productFeatureCategoryCreate,
    productFeatureCategoryEdit,
    productFeatureCategoryList,
} from './product/feature_category'
import {
    transactionTransactionStatusEdit,
    transactionTransactionStatusList,
} from './transaction/transaction_status'
import {
    commonUnitOfMeasureEdit,
    commonUnitOfMeasureList,
} from './common/unit_of_measure'

import { commonCurrencyList } from './common/currency'

const i18nProvider = polyglotI18nProvider(locale => {
    return englishMessages
}, 'en')

const App = (): ReactElement => {
    if (!dataProvider) return <p>Loading...</p>

    return (
        <Admin
            title="Admin"
            dataProvider={dataProvider}
            authProvider={authProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            // dashboard={Dashboard}
            loginPage={Login}
            // layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource
                name="transaction/transaction"
                list={transactionTransactionList}
                edit={transactionTransactionEdit}
            />
            <Resource
                name="transaction/transaction_origin"
                list={transactionTransactionOriginList}
                edit={transactionTransactionOriginEdit}
            />
            <Resource
                name="transaction/transaction_status"
                list={transactionTransactionStatusList}
                edit={transactionTransactionStatusEdit}
            />
            <Resource
                name="transaction/transaction_bag"
                list={transactionTransactionBagList}
                show={transactionTransactionBagShow}
            />
            <Resource
                name="transaction/transaction_item"
                list={transactionTransactionItemList}
                show={transactionTransactionItemShow}
            />
            <Resource
                name="transaction/transaction_payment"
                list={transactionTransactionPaymentList}
                show={transactionTransactionPaymentShow}
            />
            <Resource
                name="product/product"
                list={productProductList}
                edit={productProductEdit}
            />
            <Resource
                name="product/product_category"
                list={productProductCategoryList}
                edit={productProductCategoryEdit}
                create={productProductCategoryCreate}
            />
            <Resource
                name="product/product_type"
                list={productProductTypeList}
                edit={productProductTypeEdit}
            />
            <Resource
                name="product/feature"
                list={productFeatureList}
                edit={productFeatureEdit}
            />
            <Resource
                name="product/feature_category"
                list={productFeatureCategoryList}
                edit={productFeatureCategoryEdit}
                create={productFeatureCategoryCreate}
            />
            <Resource
                name="product/feature_type"
                list={productFeatureTypeList}
                edit={productFeatureTypeEdit}
            />
            <Resource
                name="payment/payment"
                list={paymentPaymentList}
                edit={paymentPaymentEdit}
            />
            <Resource
                name="product/product_type"
                list={productProductTypeList}
                edit={productProductTypeEdit}
            />
            <Resource
                name="payment/payment_action"
                list={paymentPaymentActionList}
                edit={paymentPaymentActionEdit}
            />
            <Resource
                name="payment/payment_gateway"
                list={paymentPaymentGatewayList}
                edit={paymentPaymentGatewayEdit}
            />
            <Resource
                name="payment/payment_log"
                list={paymentPaymentLogList}
                edit={paymentPaymentLogEdit}
            />
            <Resource name="payment/credit_card_token" list={ListGuesser} />
            <Resource
                name="payment/credit_card_type"
                list={paymentCreditCardTypeList}
                edit={paymentCreditCardTypeEdit}
            />
            <Resource
                name="cart/cart"
                list={cartCartList}
                edit={cartCartEdit}
            />
            <Resource
                name="cart/cart_item"
                list={cartCartItemList}
                edit={cartCartItemEdit}
            />

            <Resource
                name="provider/provider"
                list={providerProviderList}
                edit={providerProviderEdit}
            />

            <Resource
                name="site/site"
                list={siteSiteList}
                edit={siteSiteEdit}
            />

            <Resource
                name="common/country"
                list={commonCountryList}
                edit={commonCountryEdit}
            />
            <Resource name="common/currency" list={commonCurrencyList} />
            <Resource
                name="common/state"
                list={commonStateList}
                edit={commonStateEdit}
            />
            <Resource
                name="common/city"
                list={commonCityList}
                edit={commonCityEdit}
            />

            <Resource
                name="common/language"
                list={commonLanguageList}
                edit={commonLanguageEdit}
            />
            <Resource
                name="common/unit_of_measure"
                list={commonUnitOfMeasureList}
                edit={commonUnitOfMeasureEdit}
            />
            <Resource
                name="user/user"
                list={userUserList}
                create={userUserCreate}
                edit={userUserEdit}
            />
            <Resource
                name="user/user_role"
                list={userUserRoleList}
                edit={userUserRoleEdit}
            />
            <Resource
                name="user/user_state"
                list={userUserStateList}
                edit={userUserStateEdit}
            />
            <Resource
                name="user/login_type"
                list={userLoginTypeList}
                edit={userLoginTypeEdit}
            />

            <Resource name="user/user_phone" list={ListGuesser} />
            <Resource name="user/user_address" list={ListGuesser} />
            <Resource name="user/user_IP" list={ListGuesser} />
            <Resource name="user/user_topup_phone" list={ListGuesser} />

            <Resource
                name="notification/notification"
                list={notificationNotificationList}
                edit={notificationNotificationEdit}
            />
            <Resource
                name="notification/notification_type"
                list={notificationNotificationTypeList}
                edit={notificationNotificationTypeEdit}
            />

            {/*
            <Resource name="cart/cart_item" list={ListGuesser} /> 
            */}
        </Admin>
    )
}

export default App
