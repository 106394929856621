import React from 'react'
import {
    List,
    Edit,
    DateField,
    ReferenceField,
    BooleanField,
    SimpleForm,
    TextInput,
    Datagrid,
    TextField,
    BooleanInput,
    ReferenceManyField,
    ArrayField,
    SingleFieldList,
    ChipField,
    SelectArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    NumberField,
} from 'react-admin'

export const productProductEdit = ({ ...props }) => {
    console.log(props)
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source="id" />
                <DateField source="created" />
                <DateField source="updated" />
                <BooleanInput source="visible" />
                <TextInput source="name" />
                <TextInput source="path" />
                <TextInput source="displayName" />
                <TextInput source="description" />
                <ReferenceInput
                    source="productTypeId"
                    reference="product/product_type"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberField source="price" />
                <ReferenceInput
                    source="providerId"
                    reference="provider/provider"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceArrayInput
                    source="featureIds"
                    reference="product/feature"
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    source="productCategoryIds"
                    reference="product/product_category"
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    )
}

export const productProductList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                {/* <TextField source="provider.name" label="Provider" /> */}
                <ReferenceField
                    label="Provider"
                    source="provider.id"
                    reference="provider/provider"
                >
                    <TextField source="displayName" />
                </ReferenceField>

                <DateField source="created" />
                <BooleanField source="visible" />
                <TextField source="displayName" />
                <ChipField label="Type name" source="productType.name" />
                <NumberField source="price" />
                <TextField label="CURR" source="provider.currencyId" />
                <ArrayField source="features">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="productCategories">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>

                {/* <ReferenceManyField
                    source="features"
                    reference="product/feature"
                >
                    <TextField source="name" />
                </ReferenceManyField> */}
            </Datagrid>
        </List>
    )
}
