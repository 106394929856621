import React from 'react'
import {
    List,
    Create,
    Edit,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    ReferenceField,
    SimpleForm,
    TextInput,
    PasswordInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
} from 'react-admin'

// export const User_userCreate = ({ permissions, ...props }) => (
export const userUserCreate = ({ ...props }) => (
    <Create {...props}>
        <SimpleForm defaultValue={{ role: 'user' }}>
            {/* <TextInput source="name" validate={[required()]} /> */}
            <TextInput source="email" />
            <PasswordInput source="password" />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <BooleanInput source="isActive" />
            <BooleanInput source="allowNotifications" />
        </SimpleForm>
    </Create>
)

export const userUserEdit = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <PasswordInput source="password" initiallyVisible />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <BooleanInput source="is_active" />
            <BooleanInput source="allowNotifications" />
            <ReferenceInput
                reference="user/user_role"
                source="roleId"
                label="Role"
            >
                <SelectInput source="id" optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                reference="common/currency"
                source="currencyId"
                label="Currency"
                perPage={250}
            >
                <SelectInput source="id" optionText="id" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const userUserList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <DateField source="created" />
                <DateField source="updated" />
                <EmailField source="email" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <BooleanField source="isActive" />
                <BooleanField source="allowNotifications" />

                <ReferenceField
                    source="notificationTypeId"
                    reference="notification/notification_type"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="currencyId" reference="common/currency">
                    <TextField source="id" />
                </ReferenceField>

                <ReferenceField source="roleId" reference="user/user_role">
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
